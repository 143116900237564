<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Blog - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>
        <a-form-item
          label="Nadpis"
          :validate-status="error('ble_name') ? 'error' : ''"
          :help="error('ble_name') || ''"
        >
          <a-input placeholder="Nadpis" v-decorator="['ble_name', {rules: [{max: 100, message: 'Nadpis nemůže být delší než 100 znaků!'}, {required: true, message: 'Nadpis musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Obsah"
          :validate-status="error('ble_content') ? 'error' : ''"
          :help="error('ble_content') || ''"
        >
          <ckeditor @ready="initCKEditor" :editor="editor" :config="editorConfig" class="editor" v-model="editorContent"></ckeditor>
          <a-input class="d-none" v-decorator="['ble_content', {rules: [{required: true, message: 'Obsah musí být vyplněn!'}]}]"></a-input>
        </a-form-item>

        <a-form-item label="Zveřejnit?">
          <a-checkbox v-decorator="['ble_is_active', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>

        <a-form-item
          label="Vyber kategorii"
          :validate-status="error('bcy_id') ? 'error' : ''"
          :help="error('bcy_id') || ''"
        >
          <a-select placeholder="-- Vyber kategorii --" :loading="item.bcy_id.loading" :options="blogCategories.map(x => { return { value: x.bcy_id, label: x.languages.length > 0 ? '# ' + x.bcy_id + ' | ' + x.languages[0].pivot.bce_name : '# ' + x.bcy_id + ' | není nastaveno' } })" v-decorator="['bcy_id', {rules: [{required: true, message: 'Kategorie musí být vyplněn!'}]}]"></a-select>
        </a-form-item>

        <a-form-item
          label="Vyber tagy"
          :validate-status="error('tags') ? 'error' : ''"
          :help="error('tags') || ''"
        >
          <a-select placeholder="-- Vyber tagy --" :loading="item.tags.loading" mode="tags" :options="tags.map(x => { return { value: x.tag_id.toString(), label: x.blog_tag_languages.length > 0 ? '# ' + x.tag_id + ' | ' + x.blog_tag_languages[0].pivot.bte_name : '# ' + x.tag_id + ' | není nastaveno' } })" v-decorator="['tags', {rules: [{required: true, message: 'Tagy musí být vyplněn!'}]}]"></a-select>
        </a-form-item>

        <my-form-image-item
          title="Obrázek"
          subject="Blog"
          decorator-name="existing"
          :image="thumbImage"
          :image-next-count="thumbImageNextCount"
          :images="thumbImages"
          :on-image-switch-change="onThumbImageSwitchChange"
          :on-next-images-click="onNextThumbImagesClick"
          :form="form"
          :required="true"
        ></my-form-image-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError()) || !this.isThumbImageSelected(false)">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../services/forms'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'
import editorMixin from '@/services/editorMixin'

function typeOfNaN(x) {
  return Number.isNaN(x) || isNaN(x)
}

export default {
  components: {
    ActionTools, LanguageTab, myFormImageItem,
  },
  mixins: [imageSizeMixin, thumbImageMixin, editorMixin],
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data() {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/blog',
          title: 'Seznam',
        },
      ],
      item: {
        iae_id: null,
        tags: {
          loading: false,
          active: [],
        },
        bcy_id: {
          loading: false,
          active: [],
        },
        languages: [],
      },
      loaded: [],
      dependencies: ['tags', 'bcy_id'],
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
    tags: function () {
      return this.$store.getters['blogTag/currentLanguage']('')
    },
    blogCategories: function () {
      return this.$store.getters['blogCategory/currentLanguage']('')
    },
  },
  methods: {
    initData() {
      this.dependencies.forEach((value) => {
        this.item[value].loading = true
      })
      Promise.all([
        this.$store.dispatch('blogTag/getList'),
        this.$store.dispatch('blogCategory/getList'),
      ])
        .catch(() => {})
        .finally(() => {
          this.dependencies.forEach((value) => {
            this.item[value].loading = false
          })
          this.loaded.push(this.language)
        })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('blog/post', this.getData())
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      const data = new FormData()
      const tags = []
      if (this.form.getFieldValue('tags') !== undefined) {
        this.form.getFieldValue('tags').forEach(x => {
          if (!typeOfNaN(x) && this.tags.find(y => y.tag_id === parseInt(x))) {
            tags.push(parseInt(x))
          }
        })
      }
      const jsonObject = {
        bcy_id: this.form.getFieldValue('bcy_id'),
        iae_id: this.form.getFieldValue('existing'),
        languages: this.item.languages.filter(x => x.ble_name !== undefined && x.ble_name.trim().length > 0 && x.ble_content !== undefined && x.ble_content.trim().length > 0),
        tags: {
          new: {
            count: this.item.tags.active[0].items.length,
            languages: this.item.tags.active[0].items.length === 0 ? [] : this.item.tags.active,
          },
          existing: tags,
        },
      }
      data.append('json', JSON.stringify(jsonObject))
      return data
    },
    handleChange(oldLanguage) {
      const foundObj = { blog: false, tag: false }
      const newObj = {
        blog: {
          lge_id: oldLanguage,
          ble_content: this.form.getFieldValue('ble_content'),
          ble_name: this.form.getFieldValue('ble_name'),
          ble_is_active: this.form.getFieldValue('ble_is_active'),
        },
        tag: {
          lge_id: oldLanguage,
          items: this.form.getFieldValue('tags') === undefined ? [] : this.form.getFieldValue('tags').filter(x => {
            return !(!typeOfNaN(x) && this.tags.find(y => y.tag_id === parseInt(x)))
          }),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.blog
          foundObj.blog = true
          return true
        }
      })
      this.item.tags.active.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.tags.active[key] = newObj.tag
          foundObj.tag = true
          return true
        }
      })
      if (!foundObj.blog) {
        this.item.languages.push(newObj.blog)
      }
      if (!foundObj.tag) {
        this.item.tags.active.push(newObj.tag)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
  },
  created() {
    if (this.language !== null && !this.loaded.includes(this.language)) {
      this.initData()
    }
  },
  watch: {
    editorContent(newValue) {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          ble_content: newValue,
        })
      })
    },
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = { blog: this.item.languages.find(x => x.lge_id === newValue), tags: this.item.tags.active.find(x => x.lge_id === newValue) }
        let newObj
        if (foundObj.blog === undefined) {
          newObj = {
            ble_is_active: true,
            ble_name: undefined,
            ble_content: undefined,
          }
          this.editorContent = ''
        } else {
          newObj = {
            ble_is_active: foundObj.blog.ble_is_active,
            ble_name: foundObj.blog.ble_name,
            ble_content: foundObj.blog.ble_content,
          }
          this.editorContent = foundObj.blog.ble_content
        }
        newObj.tags = this.form.getFieldValue('tags') === undefined ? [] : this.form.getFieldValue('tags').filter(x => { return !typeOfNaN(x) && this.tags.find(y => y.tag_id === parseInt(x)) })
        if (foundObj.tags !== undefined) {
          newObj.tags = newObj.tags.concat(foundObj.tags.items)
        }
        this.form.setFieldsValue(newObj)
        if (!this.loaded.includes(this.language)) {
          this.initData()
        }
        this.form.validateFields()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
